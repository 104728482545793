import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import {
  CTASection,
  CardsBlockSection,
  CompatibleDevices,
  ContentBlockSection,
  FAQSection,
  FeaturesAccordion,
  Reviews,
} from '@containers';
import { CTAFeatures, Card, Layout, SEO, Shape, Text } from '@components';
import { Box } from '@core';
import { usePortal } from '@hooks';
import { PAGE_DATA } from '@shared/constants/pages/product-page-windows';
import { getModalConfig, getModalProps } from '@shared/constants/pages/product-page-common';
import { scrollHandler, timerHandler } from '@shared/modal-handlers';
import { useServersQuery } from '@data/servers';

const WindowsPage = ({ location, data }) => {
  const {
    metaData,
    headerCta,
    compatibleDevices,
    faq,
    features,
    reviews,
    footerCta,
    surfOnlineSmoothly,
    moneyBackGuarantee,
    steps,
    perksBefore,
    perksAfter,
  } = PAGE_DATA({ images: data.allFile.edges });

  const { t } = useTranslation();

  const { locationCount } = useServersQuery();

  // TODO: re-enable modal when it's updated with new design
  // const wasShownRef = useRef(false);
  // const { showModal, Modal: ModalComponent } = usePortal(
  //   getModalConfig({
  //     viewEventLabel: 'windows_popup_view',
  //     closeEventLabel: 'windows_popup_close',
  //   })
  // );
  //
  // useEffect(() => {
  //   const scrollListener = scrollHandler(wasShownRef, showModal);
  //   const timer = setTimeout(timerHandler(wasShownRef, showModal), 10000);
  //
  //   window.addEventListener('scroll', scrollListener);
  //
  //   return () => {
  //     clearTimeout(timer);
  //     window.removeEventListener('scroll', scrollListener);
  //   };
  // }, [showModal]);

  return (
    <Layout
      location={location}
      wrapperProps={{ backgroundColor: 'unset' }}
      navProps={{ initialBackgroundColor: { _: '#BFE2FF', lg: 'transparent' } }}
    >
      <SEO {...metaData(t)} />
      {/* // TODO: re-enable modal when it's updated with new design */}
      {/* <ModalComponent
        {...getModalProps({
          pricePerMonth: plans[0].pricePerMonth,
          googleEventLabel: 'windows_popup_CTA',
        })}
      /> */}
      <CTASection
        data={headerCta}
        descriptionComponent={CTAFeatures}
        sectionProps={{
          wrapperProps: { pt: 0 },
        }}
        wrapperProps={{
          backgroundColor: '#BFE2FF',
          pt: 80,
        }}
        contentProps={{
          pt: 120,
          pb: 160,
        }}
      />
      <CompatibleDevices
        {...compatibleDevices(t)}
        showDivider
        desktopCard={Card.IconLink}
        sectionProps={{ wrapperProps: { pb: { _: 80, lg: 120 } } }}
      />
      <CardsBlockSection
        {...steps({ t })}
        itemComponent={Card.Feature}
        itemsOnDesktop={3}
        sectionProps={{ wrapperProps: { pb: { _: 64, md: 88 } } }}
      />
      <CardsBlockSection
        {...perksBefore({ locationCount, t })}
        itemComponent={Card.Feature}
        itemsOnDesktop={2}
        sectionProps={{ wrapperProps: { py: { md: 64 } } }}
      />
      <ContentBlockSection
        {...surfOnlineSmoothly({ t })}
        shape={Shape.Blob2}
        shapeProps={{
          left: { _: '-30%', sm: 0, md: '40%' },
          top: 0,
        }}
      />
      <CardsBlockSection
        {...perksAfter({ t })}
        itemComponent={Card.Feature}
        itemsOnDesktop={2}
        sectionProps={{ wrapperProps: { pb: { _: 64, md: 88 } } }}
      />
      <Box mt={{ _: 24, md: 32 }} mb={{ _: 24, md: 66 }}>
        <FeaturesAccordion {...features({ t })} />
      </Box>
      <Reviews {...reviews(t)} />
      <ContentBlockSection
        {...moneyBackGuarantee({ t })}
        shape={Shape.Blob4}
        shapeProps={{ left: { _: '10%', md: '50%' }, top: { _: '5%', md: 0 } }}
        sectionProps={{ wrapperProps: { py: { _: 64, md: 88 } } }}
      />
      <FAQSection {...faq({ locationCount })} />
      <CTASection
        data={footerCta}
        showAvailablePlatforms
        titleComponent={Text.Heading2}
        sectionProps={{
          wrapperProps: { pb: 0 },
        }}
        wrapperProps={{
          backgroundColor: '#BFE2FF',
        }}
        contentProps={{
          pt: 160,
          pb: 80,
        }}
      />
    </Layout>
  );
};

export default WindowsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "vpnForWindows", "plans", "checkout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: [
            "product-page/win-header-image-tablet.png"
            "product-page/win-header-image-mobile.png"
            "product-page/win-header-image.png"
            "product-page/surf-online-smoothly.png"
            "product-page/30-day-guarantee.png"
            "product-page/safebrowse.png"
            "product-page/data-breach-monitor.png"
            "product-page/multihop.png"
            "product-page/solid-no-logs.png"
            "product-page/network-kill-switch.png"
          ]
        }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        ...imageData
      }
    }
  }
`;
