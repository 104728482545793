import React from 'react';

import { Button } from '@core';
import { LOADING_OPTIONS, LinkWrapper, Text } from '@components';
import { COMPATIBLE_DEVICES } from '@shared/constants/pages/product-page-common';
import { APPS_LINKS } from '@shared/constants/apps';
import { META_IMAGE, PRICING_LINK, SUPPORT_EMAIL } from '@shared/constants/constants';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { formatLink } from '@shared/format-helpers';
import { trackCustomGA4Event } from '@shared/google-utils';
import { APP_PARAMS, GA4_EVENTS } from '@shared/constants/google';
import { getFetchedImage } from '@shared/image-helpers';
// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';
// Steps Images
import DownloadInstall from '@images/product-page/download-install.svg';
import ConnectEnjoy from '@images/product-page/connect-enjoy.svg';
import UpgradeAnytime from '@images/product-page/upgrade-anytime.svg';
// Perks images
import SeamlessStreaming from '@images/product-page/optimized-servers-streaming.svg';
import ChangeLocations from '@images/product-page/change-locations.svg';
import BlockMaliciousPrograms from '@images/product-page/block-malicious-programs.svg';
import AccessAllWebsites from '@images/product-page/access-all-websites.svg';

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('vpnForWindows:metaData.title'),
    description: t('vpnForWindows:metaData.description'),
    image: META_IMAGE,
    keywords: [
      'best free VPN for Windows',
      'Atlas Windows VPN',
      'VPN for PC',
      'Atlas VPN for Windows 10',
      'free VPN for PC',
      'VPN on Windows',
    ],
  }),
  headerCta: {
    text: ({ isLoading, discountPercentage, pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForWindows:headerCta.title'),
      subtitle: t('vpnForWindows:headerCta.subtitle'),
      description: [
        { title: t('vpnForWindows:headerCta.productFeatures.unlimitedDevicesAndData') },
        { title: t('vpnForWindows:headerCta.productFeatures.streamingOptimized') },
        { title: t('vpnForWindows:headerCta.productFeatures.protection') },
      ],
      buttons: [
        {
          title: t('common:actions.downloadFree'),
          link: APPS_LINKS.windows,
          buttonProps: {
            variant: 'outlined',
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.windows },
          },
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(images, 'product-page/win-header-image-mobile.png'),
        alt: 'windows page header',
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(images, 'product-page/win-header-image-tablet.png'),
        alt: 'windows page header',
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(images, 'product-page/win-header-image.png'),
        alt: 'windows page header',
        loading: LOADING_OPTIONS.eager,
        sizes: '2350px',
        position: {
          top: -80,
          height: 717,
          maxWidth: 2350,
        },
      },
    }),
  },
  compatibleDevices: (t) => ({
    title: t('common:getAtlasVpnForAllDevices'),
    cards: COMPATIBLE_DEVICES(t),
    mobileCards: COMPATIBLE_DEVICES(t),
  }),
  steps: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    title: t('vpnForWindows:stepCards.title'),
    items: [
      {
        image: DownloadInstall,
        step: 1,
        title: t('vpnForWindows:stepCards.downloadInstall.title'),
        description: t('vpnForWindows:stepCards.downloadInstall.description'),
      },
      {
        image: ConnectEnjoy,
        step: 2,
        title: t('vpnForWindows:stepCards.connectEnjoy.title'),
        description: t('vpnForWindows:stepCards.connectEnjoy.description'),
      },
      {
        image: UpgradeAnytime,
        step: 3,
        title: t('vpnForWindows:stepCards.upgradeAnytime.title'),
        description: t('vpnForWindows:stepCards.upgradeAnytime.description', {
          serverCount: SERVER_COUNT,
        }),
      },
    ],
  }),
  perksBefore: ({ locationCount, t }) => ({
    title: t('vpnForWindows:whyYouNeedAtlasVpn'),
    items: [
      {
        image: SeamlessStreaming,
        title: t('vpnForWindows:perkCards.seamlessStreaming.title'),
        description: t('vpnForWindows:perkCards.seamlessStreaming.description'),
      },
      {
        image: ChangeLocations,
        title: t('vpnForWindows:perkCards.changeLocations.title'),
        description: t('vpnForWindows:perkCards.changeLocations.description', {
          serverCount: SERVER_COUNT,
          locationCount,
        }),
      },
    ],
  }),
  surfOnlineSmoothly: ({ t }) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/surf-online-smoothly.png'),
        title: t('vpnForWindows:contentBlocks.surfOnlineSmoothly.title'),
        description: t('vpnForWindows:contentBlocks.surfOnlineSmoothly.description'),
        isReversed: true,
      },
    ],
  }),
  perksAfter: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    items: [
      {
        image: BlockMaliciousPrograms,
        title: t('vpnForWindows:perkCards.blockMaliciousPrograms.title'),
        description: t('vpnForWindows:perkCards.blockMaliciousPrograms.description'),
      },
      {
        image: AccessAllWebsites,
        title: t('vpnForWindows:perkCards.accessAllWebsitesAndApps.title'),
        description: t('vpnForWindows:perkCards.accessAllWebsitesAndApps.description'),
      },
    ],
  }),
  features: ({ ctaLink = PRICING_LINK, t } = {}) => ({
    title: t('vpnForWindows:features.title'),
    data: [
      {
        title: t('vpnForWindows:features.shield.title'),
        description: t('vpnForWindows:features.shield.description'),
        image: { src: getFetchedImage(images, 'product-page/safebrowse.png'), alt: 'shield' },
      },
      {
        title: t('vpnForWindows:features.dataBreachMonitor.title'),
        description: t('vpnForWindows:features.dataBreachMonitor.description'),
        image: {
          src: getFetchedImage(images, 'product-page/data-breach-monitor.png'),
          alt: 'Data breach monitor',
        },
      },
      {
        title: t('vpnForWindows:features.multiHop.title'),
        description: t('vpnForWindows:features.multiHop.description'),
        image: { src: getFetchedImage(images, 'product-page/multihop.png'), alt: 'MultiHop' },
      },
      {
        title: t('vpnForWindows:features.noLogs.title'),
        description: t('vpnForWindows:features.noLogs.description'),
        image: {
          src: getFetchedImage(images, 'product-page/solid-no-logs.png'),
          alt: 'Solid no logs policy',
        },
      },
      {
        title: t('vpnForWindows:features.killSwitch.title'),
        description: t('vpnForWindows:features.killSwitch.description'),
        image: {
          src: getFetchedImage(images, 'product-page/network-kill-switch.png'),
          alt: 'Network kill switch',
        },
      },
    ],
    button: {
      title: t('common:actions.getAtlasVpn'),
      link: ctaLink,
      buttonProps: {
        customEvent: {
          eventName: GA4_EVENTS.benefitsMainCtaClick,
        },
      },
    },
    link: {
      title: t('common:links.seeListFeatures'),
      link: formatLink(t('navigation:paths.features')),
      customEvent: { eventName: GA4_EVENTS.fullFeaturesClick },
    },
    onOpenItemDesktop: (item) =>
      trackCustomGA4Event({ eventName: GA4_EVENTS.benefitsItemClick(item + 1) }),
    onOpenItemMobile: ({ currentPage }) =>
      trackCustomGA4Event({ eventName: GA4_EVENTS.benefitsItemClick(currentPage + 1) }),
  }),
  reviews: (t) => ({
    title: t('vpnForWindows:reviews.title'),
  }),
  moneyBackGuarantee: ({ t }) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/30-day-guarantee.png'),
        title: t('common:moneyBackGuarantee14.title'),
        description: t('common:moneyBackGuarantee14.description'),
      },
    ],
  }),
  pricing: ({ t }) => ({
    title: t('vpnForWindows:pricingSection.title'),
    description: t('vpnForWindows:pricingSection.description'),
    footerProps: {
      text: t('vpnForWindows:pricingSection.notReadyYet'),
      button: {
        link: APPS_LINKS.windows,
        title: t('common:actions.download'),
        buttonProps: {
          customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.windows },
        },
      },
    },
  }),
  faq: ({ locationCount }) => ({
    title: 'Frequently asked questions',
    items: [
      {
        header: 'Can you use Atlas VPN for free?',
        content:
          'Yes, our best free VPN for Windows version features multiple servers you can connect to without paying a cent. You can upgrade to Premium at any point to increase the number of available servers, enjoy additional features, and boost performance.',
      },
      {
        header: 'Why should you upgrade to Premium?',
        listLabel: 'Premium services bring:',
        listItems: [
          'Video-optimized servers',
          `${SERVER_COUNT} servers in ${locationCount} locations worldwide`,
          '24/7 customer support',
          'Simultaneous Premium services usage on an unlimited number of devices',
          'Privacy Pro servers for more security',
          'Access to Android TV and Amazon Fire TV apps',
          'No data limit and better speed',
        ],
      },
      {
        header: 'What refund policy does Atlas VPN follow?',
        content: (
          <span>
            We follow a strict 30-day money-back guarantee. You can request a refund for your
            subscription by contacting our support specialists at{' '}
            <LinkWrapper link={SUPPORT_EMAIL}>
              <Button type="button" variant="text">
                <Text.Body2 as="span" color="inherit">
                  support@atlasvpn.com
                </Text.Body2>
              </Button>
            </LinkWrapper>{' '}
            or via live chat. To make the refund process easier, please provide us with the email
            associated with the Premium payment.
            <br />
            <br />
            Subscriptions purchased via the Apple Store can only be refunded via the Apple Store.
            However, our support team can help you get a refund for a subscription purchased via the
            Google Play Store. To do so, provide us with the Google order number and your Google
            Play email address.
          </span>
        ),
      },
      {
        header: 'Does Atlas VPN monitor users’ browsing and private information?',
        content:
          'Atlas VPN follows a strict no-logs policy and reassures that no private information is stored by our services. We only gather the information necessary to provide our services (such as payment information). More details on data collection and retention policies can be found in our Privacy Policy.',
      },
      {
        header: 'How do recurring payments work?',
        content: (
          <span>
            Upon completing your transaction and clicking on “Complete purchase,” you accept our
            Terms and Conditions. The subscription plan automatically renews until canceled. Contact
            our support specialists if you wish to cancel your subscription at any given time. If
            you are interested in our data collection, retention, and usage practices, read our
            Privacy Policy. You can also cancel subscriptions to emails about the latest news
            related to Atlas VPN. Simply send us an email at{' '}
            <LinkWrapper link={SUPPORT_EMAIL}>
              <Button type="button" variant="text">
                <Text.Body2 as="span" color="inherit">
                  support@atlasvpn.com
                </Text.Body2>
              </Button>
            </LinkWrapper>
            .
          </span>
        ),
      },
    ],
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForWindows:footerCta.title'),
      description: t('vpnForWindows:footerCta.description'),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'windows page footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'windows page footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'windows page footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'windows page footer',
      },
      desktop: {
        src: footerImage,
        alt: 'windows page footer',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
